import {initializeApp} from "firebase/app";
import {getAuth} from "firebase/auth";
import {getFirestore} from "firebase/firestore";
import {getStorage} from "firebase/storage";
import {getFunctions} from "@firebase/functions";
const firebaseConfig = {
    apiKey: "AIzaSyBIwJ_zOeXG-XNtQhs2gvche3-c4mahVLM", // FIREBASE_API_KEY
    authDomain: "boris-rocks.firebaseapp.com", // FIREBASE_AUTH_DOMAIN
    projectId: "boris-rocks", // FIREBASE_PROJECT_ID
    storageBucket: "boris-rocks.appspot.com",
    messagingSenderId: "417601774794",
    appId: "1:417601774794:web:498b128731a440fe20c033",
    measurementId: "G-N3C10VN9DD"
};

// const isStaging = process.env.IS_VERCEL_PREVIEW === 'true' || process.env.NEXT_PUBLIC_IS_VERCEL_PREVIEW === 'true';
// const isDevelopment = process.env.NODE_ENV !== 'production';
//
//
//
// if (isStaging || isDevelopment) {
//     firebaseConfig = {
//         apiKey: "AIzaSyCH50T8sAI-Fs2VkhxkWJC-voYl8vy8mVs",
//         authDomain: "social-ocean-staging-8f25e.firebaseapp.com",
//         projectId: "social-ocean-staging-8f25e",
//         storageBucket: "social-ocean-staging-8f25e.appspot.com",
//         messagingSenderId: "146948862740",
//         appId: "1:146948862740:web:2afe61acba195cc7af4801",
//         measurementId: "G-FKLVD43LKQ"
//     };
// }



const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const firebaseFunctions = getFunctions(app);

// const analytics = getAnalytics(app);


export {auth, db, app, storage, firebaseFunctions};
// export {auth, db, app, storage, firebaseFunctions, analytics};




