'use client'
import {useState} from "react";
import {httpsCallable} from "@firebase/functions";
import {firebaseFunctions} from "~/components/firebase-app/firebase-app";
import LoadingSpinner from "~/components/loading-spinner/loading-spinner";

const EmailForm = (props: any) => {
    const trackEmailSubscription = props.trackEmailSubscription;
    const [email, onChangeEmail] = useState('');
    const [name, onChangeName] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [isSuccess, setSuccess] = useState(false);

    const subscribeUser = async () => {
        if (isLoading) {
            return;
        }
        try {
            trackEmailSubscription(email);
        } catch (e) {
            console.log("Failed to track email subscription", e);
        }
        console.log(email);
        const emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        if (!emailReg.test(email)) {
            return alert("Oops! Seems like the entered email is not correct!");
        }
        setLoading(true);
        const verifyCode = httpsCallable(firebaseFunctions, 'subscribeToEmailListPromo');
        const response = await verifyCode({email: email, name: name.length > 1 ? name : null});
        type SubscribeToEmailListResponse = {
            success: number,
        }
        const json = response.data as SubscribeToEmailListResponse;
        console.log('json', json);
        if (json?.success == 1) {
            setLoading(false);
            setSuccess(true);
        } else {
            alert("Something went wrong. Please try again")
            setLoading(false);
        }

    }
    return (
        <>
            <div className={"mt-20"}>
                <form className="w-full md:max-w-md mx-auto">
                    <div className={"text-center"}>
                        <h3 className={"text-4xl font-semibold"}>Enjoy trading?</h3>
                        <div className={"text-xl font-light mt-5"}>Subscribe to our mailing list to learn more about options and
                            options trading
                            tools!
                        </div>
                    </div>

                    <div className="mt-10 md:flex md:items-center mb-6">
                        <div className="shrink">
                            <label className="block font-bold md:text-right mb-1 md:mb-0 pr-4"
                                   htmlFor="inline-full-name">
                                Name
                            </label>
                        </div>
                        <div className="grow">
                            <input
                                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                // className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                                onChange={(e) => onChangeName(e.target.value)}
                                value={name}
                                placeholder="Enter your name"
                                id={"inline-full-name"}
                            />
                        </div>
                    </div>
                    <div className="md:flex md:items-center mb-6">
                        <div className="shrink">
                            <label className="block font-bold md:text-right mb-1 md:mb-0 pr-4"
                                   htmlFor="inline-email">
                                Email
                            </label>
                        </div>
                        <div className="grow">
                            <input
                                // className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                onChange={(e) => onChangeEmail(e.target.value)}
                                value={email}
                                placeholder="Enter your email"
                                id="inline-email"
                            />
                        </div>
                    </div>
                            {isSuccess ? <div className={"text-center"}>Thank you!</div> : isLoading ? <LoadingSpinner/> : <div
                                onClick={subscribeUser}
                                className="w-44 mx-auto text-center cursor-pointer bg-yellow-400 text-black px-8 py-4 text-xl font-bold rounded-xl"
                                // className="flex-shrink-0 bg-sky-400 hover:bg-sky-700 text-sm text-white py-1 px-2 rounded cursor-pointer font-bold mr-2"
                            >
                                SUBSCRIBE
                            </div>}
                            {/*<button*/}
                            {/*    type="button">*/}
                            {/*    Sign Up*/}
                            {/*</button>*/}
                </form>


                {/*<form className="w-full mx-auto">*/}
                {/*    <div className="flex items-center border rounded border-sky-400 py-2 mx-auto">*/}


                {/*        <span className={"text-sky-500"}>|</span>*/}


                {/*    </div>*/}
                {/*</form>*/}
            </div>
        </>
    );
}
export default EmailForm;