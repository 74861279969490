'use client'
import {useState} from "react";
import Switch from "react-switch";

export default function LandingBlockPricing() {

    const basicMonthly = 9.99;
    const basicAnnual = 99.99;
    const proMonthly = 19.99;
    const proAnnual = 199.99;

    const basicAnnualPerMont = basicAnnual / 12;
    const proAnnualPerMonth = proAnnual / 12;

    const [isAnnual, setIsAnnual] = useState(true);


    return (
        <div className={"px-4 lg:px-0"}>
            <div className={"text-xl mb-14 md:mb-4 lg:mb-3 font-light"}>
                <span className={"text-4xl font-semibold"}>🥹 How much?</span>
            </div>

            <div className={"flex justify-center mt-10"}>
                <div>
                    <div onClick={() => {
                        setIsAnnual(false);
                    }}
                         style={{padding: '10pt 20pt'}}
                         className={"text-xl mx-4 text-center rounded cursor-pointer  " + (isAnnual ? "text-gray-400 bg-gray-700" : "font-semibold  btn-primary-grad")}>Monthly
                    </div>
                </div>


                <div className={"mx-4 text-left"}>
                    <div>
                        <div onClick={() => {
                            setIsAnnual(true);
                        }}
                         style={{padding: '10pt 20pt'}}
                             className={"text-xl text-center rounded cursor-pointer " + (isAnnual ? "font-semibold btn-primary-grad " : "text-gray-400 bg-gray-700")}>Annual
                        </div>
                        <div className={""}>Save up to 20%</div>
                    </div>
                </div>
            </div>

            {/*/!*  Add a switch to allow users switch between mpmthly and annual  *!/*/}
            {/*<div className={"flex justify-center ml-16"}>*/}
            {/*    <div className={"flex items-center"}>*/}
            {/*        <div className={"text-xl mx-4 " + (isAnnual ? "opacity-75" : "font-semibold") }>Monthly</div>*/}
            {/*        <Switch*/}
            {/*            onChange={(checked) => { setIsAnnual(checked);}}*/}
            {/*            checked={isAnnual}*/}
            {/*            onColor={"#4FD1C5"}*/}
            {/*        />*/}

            {/*        <div className={"mx-4 text-left"}>*/}
            {/*            <div>*/}
            {/*                <div className={"text-xl " + (isAnnual  ? "font-semibold" : "opacity-75") }>Annual</div>*/}
            {/*                <div className={""}>Save up to 20%</div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div className={"mt-10 mb-5 flex flex-col lg:flex-row align-middle justify-center"}>
                <div className={"lg:mr-10 "}>
                    <div className={"mb-4 text-5xl font-bold"}>Free</div>
                    <div className={"mb-4 text-5xl font-bold"}>
                        $0.00
                        <span className={"text-base font-normal hidden lg:inline-block"}>/ mo</span>
                    </div>
                    {isAnnual && <div className={"opacity-75 text-sm"}>
                        $0.00 paid annually
                    </div>}
                    <div className={"mb-7 mt-10 text-left flex  opacity-75"}>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                                 stroke="currentColor" className="w-6 h-6 slightly-transparent mr-2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5"/>
                            </svg>
                        </div>
                        <div>
                            <div className={"font-semibold text-xl"}>Market overview</div>
                            <div className={"text-sm"}>5 entries</div>
                        </div>
                    </div>
                    <div className={"mb-7 text-left flex  opacity-75"}>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                                 stroke="currentColor" className="w-6 h-6 slightly-transparent mr-2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5"/>
                            </svg>
                        </div>
                        <div>
                            <div className={"font-semibold text-xl"}>Company Fundamentals</div>
                            <div className={"text-sm"}>3 reports / day</div>
                        </div>
                    </div>
                    <div className={"mb-7 text-left flex opacity-75"}>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                                 stroke="currentColor" className="w-6 h-6 slightly-transparent mr-2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5"/>
                            </svg>
                        </div>
                        <div>
                            <div className={"font-semibold text-xl"}>Options Scanner</div>
                            <div className={"text-sm"}>3 companies / day</div>
                        </div>
                    </div>
                </div>

                <div className={"lg:hidden border-b border-b-fuchsia-300 w-10 md:w-20 lg:w-96 mx-auto my-10"}></div>

                <div className={"lg:ml-10 mt-10 lg:mt-0"}>
                    <div className={"mb-4 text-5xl font-bold"}>Standard</div>
                    <div className={"mb-4 text-5xl font-bold"}>
                        ${isAnnual ? basicAnnualPerMont.toFixed(2) : basicMonthly.toFixed(2)}
                        <span className={"text-base font-normal hidden lg:inline-block"}>/ mo</span>
                        <div className={"text-base font-normal block lg:hidden"}>
                            per month
                        </div>
                    </div>
                    {isAnnual && <div className={"opacity-75 text-sm"}>
                        ${basicAnnual.toFixed(2)} paid annually
                    </div>}
                    <div className={"mb-7 mt-10 text-left flex"}>
                        <div className={"text-3xl mr-2"}>
                            ∞
                        </div>
                        <div>
                            <div className={"font-semibold text-xl"}>Market overview</div>
                            <div className={"text-sm"}>Complete</div>
                        </div>
                    </div>
                    <div className={"mb-7 text-left flex"}>

                        <div className={"text-3xl mr-2"}>
                            ∞
                        </div>
                        <div>
                            <div className={"font-semibold text-xl"}>Company Fundamentals</div>
                            <div className={"text-sm"}>Unlimited</div>
                        </div>
                    </div>
                    <div className={"mb-7 text-left flex  opacity-75"}>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                                 stroke="currentColor" className="w-6 h-6 slightly-transparent mr-2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5"/>
                            </svg>
                        </div>
                        <div>
                            <div className={"font-semibold text-xl"}>Options Scanner</div>
                            <div className={"text-sm"}>3 companies / day</div>
                        </div>
                    </div>
                </div>


                <div className={"lg:hidden border-b border-b-fuchsia-400 w-10 md:w-20 lg:w-96 mx-auto my-10"}></div>

                <div className={"lg:ml-10 mt-10 lg:mt-0"}>
                    <div className={"mb-4 text-5xl font-bold"}>Premium</div>
                    <div className={"mb-4 text-5xl font-bold"}>
                        ${isAnnual ? proAnnualPerMonth.toFixed(2) : proMonthly.toFixed(2)}
                        <span className={"text-base font-normal hidden lg:inline-block"}>/ mo</span>
                        <div className={"text-base font-normal block lg:hidden"}>
                            per month
                        </div>
                    </div>
                    {isAnnual && <div className={"opacity-75 text-sm"}>
                        ${proAnnual.toFixed(2)} paid annually
                    </div>}
                    <div className={"mb-7  mt-10 text-left flex"}>

                        <div className={"text-3xl mr-2"}>
                            ∞
                        </div>
                        <div>
                            <div className={"font-semibold text-xl"}>Market overview</div>
                            <div className={"text-sm"}>Complete</div>
                        </div>
                    </div>
                    <div className={"mb-7 text-left flex"}>

                        <div className={"text-3xl mr-2"}>
                            ∞
                        </div>
                        <div>
                            <div className={"font-semibold text-xl"}>Company Fundamentals</div>
                            <div className={"text-sm"}>Unlimited</div>
                        </div>
                    </div>
                    <div className={"mb-7 text-left flex"}>

                        <div className={"text-3xl mr-2"}>
                            ∞
                        </div>
                        <div>
                            <div className={"font-semibold text-xl"}>Options Scanner</div>
                            <div className={"text-sm"}>Unlimited</div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div className={"border-b border-b-fuchsia-400 w-10 md:w-20 lg:w-96 mx-auto my-10"}></div>
            </div>

            {/*<div className={"text-xl mb-7 md:mb-4 lg:mb-3 font-light"}>*/}
            {/*    One search scans one single stock for unlimited amount of strategies and trades.*/}
            {/*    <div className={"text-sm text-gray-500 mt-2 text-center"}>*/}
            {/*        One search shows you tens of trades for one stock (if there are any): multiple credit or*/}
            {/*        debit spreads,*/}
            {/*        iron condors, and more.*/}
            {/*        <br/>*/}
            {/*        Imagine what multiple searches can do!*/}
            {/*        /!*Trying that same stock again later that day will count as a second search.*!/*/}

            {/*    </div>*/}
            {/*</div>*/}

            <div className={"text-xl mb-7 md:mb-4 lg:mb-3 font-light"}>
                <span className={"font-bold underline"}>The best part?</span> One trade easily covers the
                cost of
                the subscription for months to come 💰
            </div>
        </div>
    )
}
